<template>
  <b-modal
    id="confirm-delete"
    hide-header
    hide-footer
    centered
    v-model="modalShow"
  >
    <div
      class="d-flex flex-column align-items-center justify-center-center my-2"
    >
      <feather-icon
        id="view-departamento"
        :icon="icon"
        :class="'text-' + type + ' mb-2'"
        size="80"
      />
      <h2>{{ action }}</h2>
      <span class="text-center">{{ infoTextModal }}</span>
      <span v-show="infoOlVersion" class="text-center">
        Na sua próxima importação, aproveite para baixar e utilizar o modelo
        mais atualizado da planilha de importação de colaboradores, clicando no
        link que está disponível na tela anterior.
      </span>
      <div class="d-flex mt-2">
        <b-button
          v-if="cancelButton"
          :disabled="loading"
          variant="primary-button"
          class="mr-2"
          @click="cancelAction"
        >
          Continuar editando
        </b-button>
        <b-button
          :disabled="loading"
          :variant="buttonVariant"
          class="mr-2"
          @click="confirmAction"
        >
          {{ buttonText }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";
export default {
  components: {
    BModal,
    BButton,
  },

  props: {
    itemIdentifier: {
      required: true,
      type: String,
    },
    modalShow: {
      required: true,
      type: Boolean,
    },
    typeModal: {
      required: true,
      type: String,
    },
    infoText: {
      type: String,
    },
    collums: {
      type: String,
    },
    infoOlVersion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      textComplement: "",
      modalTypes: [
        {
          type: "success",
          icon: "CheckCircleIcon",
          action: "Importação em andamento",
          textButtonCancel: "Cancelar",
          infoText: `Notificaremos assim que acabar.`,
          buttonText: "Ok",
          buttonVariant: "custom-blue",
        },
        {
          type: "danger",
          icon: "XCircleIcon",
          action: "Erro de importação",
          infoText: `Verifique as colunas: ${this.$props.collums}`,
          buttonText: "Ok",
          buttonVariant: "custom-blue",
        },
        {
          type: "custom-blue",
          icon: "AlertCircleIcon",
          action: "CPF já existe em nossa base",
          infoText: `Confira os dados e depois salve o ${this.$props.itemIdentifier}`,
          buttonText: "Entendido",
          buttonVariant: "custom-blue",
        },
        {
          type: "warning",
          icon: "AlertCircleIcon",
          action: "Desvincular colaborador",
          infoText: "Esse cpf não estará mais ligado a empresa.",
          buttonText: "Sim, desvincular.",
          buttonVariant: "outline-warning",
          cancelButton: true,
        },
      ],
      type: "",
      action: "",
      infoTextModal: "",
      icon: "",
      buttonText: "",
      buttonVariant: "",
      cancelButton: false,
    };
  },

  methods: {
    confirmAction(modalEvent) {
      modalEvent.preventDefault();

      this.loading = true;

      this.$emit("confirmed", modalEvent);
    },
    cancelAction(modalEvent) {
      modalEvent.preventDefault();

      this.$emit("canceled", modalEvent);
    },
  },

  watch: {
    modalShow() {
      this.loading = false;

      this.modalTypes.map((item) => {
        if (item.type == this.$props.typeModal) {
          this.action = item.action;
          this.textButtonCancel = item.textButtonCancel;
          this.infoTextModal = this.$props.infoText
            ? this.$props.infoText
            : item.infoText;
          this.icon = item.icon;
          this.buttonText = item.buttonText;
          this.buttonVariant = item.buttonVariant;
          this.cancelButton = item.cancelButton;
          this.type = item.type;
        }
      });
    },
  },
};
</script>

