var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('ActionColaboradorModal',{attrs:{"itemIdentifier":"usuário","modalShow":_vm.modalCpf.showModal,"typeModal":_vm.modalCpf.typeModal,"infoText":_vm.modalCpf.infoText},on:{"confirmed":_vm.closeModalCpf}}),_c('overlay',{attrs:{"show":_vm.loading,"class-name":"p-card-form"}},[_c('validation-observer',{ref:"formItems"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"lg":"4","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"CPF","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF *","label-for":"cpf-input"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","placeholder":"000.000.000-00","autocomplete":"off"},on:{"input":_vm.returnPessoa},model:{value:(_vm.getFormData.cpf),callback:function ($$v) {_vm.$set(_vm.getFormData, "cpf", $$v)},expression:"getFormData.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"lg":"8","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome completo *","label-for":"nome-input"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome-input","autocomplete":"off","placeholder":"Nome completo","disabled":_vm.formDisabled},model:{value:(_vm.getFormData.nome),callback:function ($$v) {_vm.$set(_vm.getFormData, "nome", $$v)},expression:"getFormData.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"E-mail *","label-for":"email-input"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email-input","autocomplete":"off","placeholder":"Email"},model:{value:(_vm.getFormData.email),callback:function ($$v) {_vm.$set(_vm.getFormData, "email", $$v)},expression:"getFormData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"sm":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Sexo*","label-for":"sexo"}},[_c('validation-provider',{attrs:{"name":"Sexo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"sexo","options":_vm.sex,"variant":"custom","item-text":"name","item-value":"name","placeholder":"Selecione uma opção","label":"name"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Nenhum registro encontrado. ")]},proxy:true}],null,true),model:{value:(_vm.getFormData.sexo),callback:function ($$v) {_vm.$set(_vm.getFormData, "sexo", $$v)},expression:"getFormData.sexo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"sm":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Data de nascimento*","label-for":"nascimento"}},[_c('validation-provider',{attrs:{"name":"Data de nascimento","rules":"required|nascimento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"nascimento","raw":false,"options":_vm.options.date,"autocomplete":"off","placeholder":"00/00/0000","disabled":_vm.formDisabled},model:{value:(_vm.getFormData.nascimento),callback:function ($$v) {_vm.$set(_vm.getFormData, "nascimento", $$v)},expression:"getFormData.nascimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('sesi-profile-settings',{ref:"profileSettings",attrs:{"mode":_vm.getMode},on:{"setLoading":_vm.setLoading}}),(_vm.showTableCompanies)?_c('table-companies',{ref:"tableCompanies",attrs:{"username":_vm.getFormData.nome},on:{"setCompanies":_vm.setCompanies}}):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('button',{staticClass:"btn custom-blue button-custom-size",attrs:{"type":"button"},on:{"click":function($event){return _vm.formSubmit(true)}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Salvar usuário ")],1),(_vm.getMode === 'insert')?_c('button',{staticClass:"btn custom-blue button-custom-size",attrs:{"type":"button"},on:{"click":function($event){return _vm.formSubmit(false)}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v(" Salvar e cadastrar novo ")],1):_vm._e(),_c('button',{staticClass:"btn btn-outline-danger button-custom-size",attrs:{"type":"button"},on:{"click":_vm.cancel}},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v(" Cancelar ")],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }