<template>
  <validation-observer
    v-if="loading"
    ref="formProfileSettings"
  >
    <b-row>
      <b-col
        sm="6"
        lg="4"
        class="mb-2"
      >
        <validation-provider
          #default="{ errors }"
          name="Perfil"
          rules="required"
        >
          <!-- Perfil -->
          <b-form-group
            label="Perfil*"
            label-for="profiles"
          >
            <v-select
              id="profiles"
              v-model="getFormData.perfil"
              :options="profiles"
              variant="custom"
              item-text="descricao"
              item-value="id_perfil"
              placeholder="Selecione um perfil"
              label="descricao"
              :disabled="profilesLoading || getDisabledInActionType"
              @input="loadDataByProfile"
            >
              <template
                v-slot:no-options
              >
                Nenhum registro encontrado.
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Departamento -->
      <b-col
        v-if="!isAdminCompany"
        sm="6"
        lg="4"
        class="mb-2"
      >
        <validation-provider
          #default="{ errors }"
          name="Departamento regional"
          rules="required"
        >
          <b-form-group
            label="Departamento regional*"
            label-for="departments"
          >
            <v-select
              id="departments"
              v-model="getFormData.departamento"
              :options="departments"
              variant="custom"
              item-text="descricao"
              item-value="id_departamento"
              placeholder="Selecione um departamento"
              label="descricao"
              :disabled="departmentsDisable"
              @input="loadDataByDepartment"
            >
              <template
                v-slot:no-options
              >
                Nenhum registro encontrado.
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Regiões -->
      <b-col
        v-if="getUoProfileMode"
        sm="6"
        lg="4"
        class="mb-2"
      >
        <b-form-group
          label="Regiões relacionadas*"
          label-for="regions"
        >
          <v-select
            id="regions"
            v-model="getFormData.regioes"
            v-b-tooltip.hover.top="!getFormData.departamento ? 'Selecione um Departamento.' : ''"
            :options="regions"
            variant="custom"
            item-text="descricao"
            item-value="id_regiao"
            :placeholder="definePlaceholderRegionsSelect"
            label="descricao"
            :disabled="regionsDisable"
            :multiple="getRegionsProfile"
            selected
            @input="loadDataByRegion"
          >
            <template
              v-slot:no-options
            >
              Nenhum registro encontrado.
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <!-- Unidades -->
      <b-col
        v-if="getFormData.regioes && showUnitsSelect"
        sm="6"
        lg="4"
        class="mb-2"
      >
        <validation-provider
          #default="{ errors }"
          name="Unidades"
          rules="required"
        >
          <b-form-group
            label="Unidade*"
            label-for="units"
          >
            <v-select
              id="units"
              v-model="chosenUnit"
              :options="units"
              variant="custom"
              item-text="descricao"
              item-value="id_unidade"
              placeholder="Selecione uma unidade"
              label="descricao"
              @input="chooseUnit()"
            >
              <template
                v-slot:no-options
              >
                Nenhum registro encontrado.
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Unidades Checkbox -->
      <b-col
        v-if="getFormData.regioes && showManyUnitsCheck"
        cols="12"
        class="mb-2"
      >
        <div class="d-flex flex-wrap">
          <div
            v-for="(region, i) in getFormData.regioes"
            :key="i"
            class="card-units"
          >
            <b-form-group
              label-class="font_size_label"
              :label="region.descricao"
              class="d-flex"
            >
              <small v-if="region.unidade.length === 0">
                Nenhum registro encontrado.
              </small>

              <b-form-checkbox-group
                id="checkbox-units"
                v-model="getFormData.unidades"
                name="checkbox-units"
              >
                <b-form-checkbox
                  v-for="(unit, j) in region.unidade"
                  :key="j"
                  :value="unit.id_unidade"
                >
                  {{ unit.descricao }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row
      v-if="canByNationalBaseRule && !isAdminCompany"
    >
      <b-col
        sm="6"
        lg="4"
      >
        <b-form-group
          label="Atua com Empresas de Base Nacional ? *"
          label-for="nationalBaseOption"
        >
          <b-form-radio-group
            v-model="nationalBaseOption"
            v-b-tooltip.hover.top="getNationalBaseMessageInfo"
            :options="nationalBaseRadioOptions"
            class="demo-inline-spacing mb-1"
            value-field="value"
            text-field="text"
            :disabled="nationalBaseOptionsDisable || getDisabledInActionType"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="nationalBaseOption"
        sm="6"
        lg="4"
        class="mb-2"
      >
        <b-form-group
          label="Tipo de atuação*"
          label-for="nationalBaseActivityType"
        >
          <validation-provider
            #default="{ errors }"
            name="Tipo de atuação"
            :rules="nationalBaseOption ? 'required' : ''"
          >
            <v-select
              id="nationalBaseActivityType"
              v-model="getFormData.tipoAtuacao"
              :options="nationalBaseActivityTypes"
              variant="custom"
              item-text="descricao"
              item-value="id_tipo_atuacao"
              placeholder="Selecione uma opção"
              label="descricao"
              :disabled="nationalBaseOptionsDisable || getDisabledInActionType"
            >
              <template
                v-slot:no-options
              >
                Nenhum registro encontrado.
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  getDepartments, getNationalBaseActivityTypes,
  getProfiles,
  getRegions,
} from '@/views/custom-pages/gerenciarUsuarios/requests'
import chavePerfil from '@/enum/chavePerfil'
import { actions, subjects } from '@/libs/acl/rules'

export default {
  components: {
    BFormRadioGroup,
    ValidationObserver,
    ValidationProvider,
    BFormCheckboxGroup,
    BFormCheckbox,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
  },
  props: {
    className: {
      type: String,
      default: '',
    },

    mode: {
      type: String,
      default: 'insert',
    },
  },

  data() {
    return {
      chavePerfil,

      departmentsDisable: true,
      profilesLoading: true,
      regionsDisable: true,
      unitsDisable: true,

      showRegionsSelect: false,
      showUnitsSelect: false,
      showManyUnitsCheck: false,

      profiles: [],
      departments: [],
      regions: [],
      units: [],

      chosenUnit: null,

      loading: true,

      nationalBaseOption: false,

      nationalBaseOptionsDisable: true,

      nationalBaseRadioOptions: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],

      nationalBaseActivityTypes: [],
    }
  },

  computed: {
    getMode() {
      return this.mode
    },

    getFormData() {
      return this.$store.getters['adminUserState/getFormData']
    },

    getProfileInFormData() {
      return this.getFormData.perfil
    },

    getDepartmentsInFormData() {
      return this.getFormData.departamento
    },

    getRegionsInFormData() {
      return this.getFormData.regioes
    },

    getProfileKey() {
      return this.getFormData.perfil ? this.getFormData.perfil.chave : null
    },

    getDnProfilesMode() {
      return [
        chavePerfil.ADMIN_DN,
        chavePerfil.SAUDE_DN,
        chavePerfil.MERCADO_DN,
      ].includes(this.getProfileKey)
    },

    getDnDrProfilesMode() {
      return [
        chavePerfil.ADMIN_DN,
        chavePerfil.SAUDE_DN,
        chavePerfil.MERCADO_DN,
        chavePerfil.ADMIN_DR,
        chavePerfil.SAUDE_DR,
        chavePerfil.MERCADO_DR,
      ].includes(this.getProfileKey)
    },

    getUoProfileMode() {
      return [
        chavePerfil.SAUDE_REGIAO,
        chavePerfil.ADMIN_UNIDADE,
        chavePerfil.VACINADOR_PADRAO,
        chavePerfil.VACINADOR_ADMIN,
        chavePerfil.SAUDE_UO,
      ].includes(this.getProfileKey)
    },

    getRegionsProfile() {
      return [
        chavePerfil.SAUDE_REGIAO,
        chavePerfil.VACINADOR_PADRAO,
        chavePerfil.VACINADOR_ADMIN,
        chavePerfil.SAUDE_UO,
      ].includes(this.getProfileKey)
    },

    getUnitProfile() {
      return this.getProfileKey === chavePerfil.ADMIN_UNIDADE
    },

    isAdminCompany() {
      return this.getProfileKey === chavePerfil.ADMIN_EMPRESA
    },

    definePlaceholderRegionsSelect() {
      if (this.getRegionsProfile) {
        return 'Selecione uma ou mais'
      }

      return 'Selecione uma região'
    },

    canByNationalBaseRule() {
      return this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL)
    },

    getNationalBaseMessageInfo() {
      if (!this.getFormData.perfil) {
        return 'Selecione um perfil.'
      }

      if (!this.getDnDrProfilesMode) {
        return 'O perfil selecionado não atua como base nacional.'
      }

      return ''
    },

    getActionTypeCoordinatorView() {
      return this.$can(actions.VISUALIZAR, subjects.TIPO_ATUACAO_BASE_NACIONAL_COORDENADOR)
    },

    getActionTypeOperatorView() {
      return this.$can(actions.VISUALIZAR, subjects.TIPO_ATUACAO_BASE_NACIONAL_OPERADOR)
    },

    getDisabledInActionType() {
      if (this.getFormData.tipoAtuacao && this.getMode === 'update') {
        return this.getActionTypeOperatorView && this.getFormData.tipoAtuacao.chave === 'COORDENADOR'
      }

      return false
    },
  },

  watch: {
    getProfileInFormData(value) {
      if (!value) {
        this.getFormData.departamento = null
        this.getFormData.regioes = []
        this.getFormData.unidades = []
        this.units = []
        this.chosenUnit = null

        this.regionsDisable = true
        this.showManyUnitsCheck = false
        this.showUnitsSelect = false
      }
    },

    getDepartmentsInFormData(value) {
      if (!value) {
        this.getFormData.regioes = []
        this.getFormData.unidades = []
        this.units = []
        this.chosenUnit = null

        this.regionsDisable = true
        this.showManyUnitsCheck = false
        this.showUnitsSelect = false
      }
    },

    getRegionsInFormData(value) {
      if (!value) {
        this.getFormData.unidades = []
        this.units = []
        this.chosenUnit = null

        this.showManyUnitsCheck = false
        this.showUnitsSelect = false
      }
    },

    nationalBaseOption(value) {
      if (!value) {
        this.getFormData.tipoAtuacao = null
      }
    },
  },

  mounted() {
    this.index()
  },

  methods: {
    async index() {
      await this.handleGetProfiles()
      await this.handleGetDepartments()

      if (this.canByNationalBaseRule) {
        await this.handleGetNationalBaseActivityTypes()
      }

      if (this.getMode === 'update') {
        if (this.getFormData.regioes) {
          await this.loadDataByDepartment()
          await this.loadDataByRegionInUpdate()
        }

        if (this.canByNationalBaseRule && this.getDnDrProfilesMode) {
          this.nationalBaseOptionsDisable = false
        }

        this.nationalBaseOption = !!(this.getFormData.tipoAtuacao && this.canByNationalBaseRule && this.getDnDrProfilesMode)

        if (this.getDnProfilesMode) {
          this.nationalBaseOptionsDisable = true
          this.nationalBaseOption = true
          this.departmentsDisable = true
        }
      }
    },

    loadDataByProfile() {
      if (this.getProfileKey === chavePerfil.ADMIN_EMPRESA) {
        this.getFormData.departamento = this.getFormData.departamentoAux
      } else {
        this.getFormData.departamento = null
      }

      this.getFormData.regioes = []
      this.getFormData.unidades = []
      this.units = []
      this.chosenUnit = null

      this.departmentsDisable = false
      this.regionsDisable = true
      this.showManyUnitsCheck = false
      this.showUnitsSelect = false

      this.nationalBaseOptionsDisable = true
      this.nationalBaseOption = false

      this.handleDnDrProfilesConfig()
      this.handleDnProfilesConfig()
    },

    handleDnDrProfilesConfig() {
      if (this.getDnDrProfilesMode) {
        this.nationalBaseOptionsDisable = false
      }
    },

    handleDnProfilesConfig() {
      if (this.getDnProfilesMode) {
        this.nationalBaseOptionsDisable = true
        this.departmentsDisable = true
        this.nationalBaseOption = true

        this.getFormData.departamento = {
          id_departamento: 1,
          descricao: 'Departamento Nacional',
        }

        this.getFormData.tipoAtuacao = this.nationalBaseActivityTypes.filter(item => item.chave === 'COORDENADOR')
      }
    },

    async loadDataByDepartment() {
      if (this.getUoProfileMode) {
        await this.handleGetRegions()
      }
    },

    loadDataByRegion() {
      if (Array.isArray(this.getFormData.regioes)) {
        this.showManyUnitsCheck = true
      } else if (this.getFormData.regioes) {
        this.units = this.getFormData.regioes.unidade
        this.showUnitsSelect = true
      }
    },

    loadDataByRegionInUpdate() {
      if (this.getRegionsProfile) {
        this.showManyUnitsCheck = true
      }

      if (this.getUnitProfile) {
        if (this.getFormData.regioes.length > 0) {
          this.getFormData.regioes.forEach(item => {
            item.unidade.forEach(unit => {
              this.units.push(unit)
            })
          })
        }

        this.chosenUnit = this.getFormData.unidades
        this.showUnitsSelect = true
      }
    },

    async handleGetProfiles() {
      this.setLoading(true)
      this.profilesLoading = true

      await getProfiles()
        .then(response => {
          const { data } = response

          if (this.getMode === 'insert') {
            this.profiles = data.filter(item => (item.id_tipo_perfil === 1) || (item.id_tipo_perfil === 2))
          } else {
            this.profiles = data
          }
        })

      this.profilesLoading = false
      this.setLoading(false)
    },

    async handleGetDepartments() {
      this.setLoading(true)
      this.departmentsDisable = true

      await getDepartments()
        .then(response => {
          const { data } = response

          this.departments = data
        })

      this.departmentsDisable = false
      this.setLoading(false)
    },

    async handleGetRegions() {
      this.setLoading(true)
      this.regionsDisable = true

      if (this.getFormData.departamento) {
        const params = {
          ativo: true,
          id_departamento: this.getFormData.departamento.id_departamento,
        }

        await getRegions(params)
          .then(response => {
            const { data } = response

            this.regions = data
          })
      }

      this.regionsDisable = false
      this.setLoading(false)
    },

    async handleGetNationalBaseActivityTypes() {
      this.setLoading(true)

      await getNationalBaseActivityTypes()
        .then(response => {
          this.nationalBaseActivityTypes = response.data
        })

      this.setLoading(false)
    },

    clear() {
      this.nationalBaseOption = false
      this.nationalBaseOptionsDisable = true
    },

    chooseUnit() {
      this.getFormData.unidades = this.chosenUnit ? [this.chosenUnit.id_unidade] : []
    },

    setLoading(loading) {
      this.$emit('setLoading', loading)
    },
  },
}
</script>

<style scoped lang="scss">
.card-units {
  width: 200px;
  background-color: #e5f0f9;
  padding: 1rem;
  margin-bottom: 1.15rem;
  margin-right: 1rem;
  border-radius: 0.357rem;
}

@media(width < 300px) {
  .card-units {
    width: auto;
    margin-right: 0;
  }
}
</style>
